export const environment = {
  name: 'prod',
  production: true,
  api: 'https://backend.brfmappen.se/api',
  seoTags: {
    default: 'BRF-Mappen',
    homeTitle: 'Hem',
    homeDescription: 'Hjärtstartare i HSB brf Backa redo att rädda liv dygnet runt. Nyheter Varje år drabbas ungefär 6 000 personer av plötsligt hjärtstopp utanför sjukhus.'
  },
  domain: 'https://brfmappen.se',
  version: '1.0.0.209',
  GoogleAnalyticsCode: 'G-N7LQCRLK99',
  fbPixelId: '384449272254210',
  batchCounter: 10,
  batchCounterMobile: 12,
  reCaptchaSiteKey: '6LfAO_sgAAAAAEzH3X2AyIN3AZMn5y1G6GUbngeB',
  brfDashboard: 'https://dashboard.brfmappen.se',
  cookieConfig: { prefix: 'brfdashboard', suffix: 'prod' }
};
